<template>
  <div class="stemcells">
    <div class="cells-container">
     <Worktrends :worklist="workinfolist" :collectinfo="Statisticslist"></Worktrends>
     <!-- <Scintroduct :Scintroduct="Scintroduct"></Scintroduct> -->
     <Donatetoknow :donatetoknow="donatetoknow"></Donatetoknow>
     <!-- <Remembrance :remembrancelist="drystyle" :title="drystyletitle"></Remembrance> -->
     <!-- <Popular :getpopularlist="popularlist" :propulartitle="'造干科普'"></Popular> -->
    </div>
  </div>
</template>

<script>
import Worktrends from './components/worktrends'
// import Scintroduct from '../Bodydonate/components/scintroduct'
import Donatetoknow from './components/donatetoknow'
// import Remembrance from '../Bodydonate/components/remembrance'
// import Popular from '../Bodydonate/components/popular'
import apiUrls from '@api'
export default {
  components : {
      Worktrends,
      // Scintroduct,
      Donatetoknow,
      // Remembrance,
      // Popular
  },
  name: 'Stemcells',
  data () {
    return {
      workinfolist: [],
      Statisticslist: {},
      drystyle: [],
      drystyletitle: "造干志愿者风采",
      popularlist: [],
      Scintroduct : {},
      donatetoknow: {},
    }
  },
  created () {
    this.getworklist()
    this.getStatistics()
    // this.getScintroduct()
    this.getdonatetoknow()
    // this.getdrystyle()
    // this.getpopular()
  },
  methods : {
     getworklist() {
      apiUrls.getstemcell({node: 'gxb_gzdt'}).then(res=>{

      this.workinfolist= res.results.data
      })
    },
    getStatistics() {
      apiUrls.getStatistics({node: 'zxgxbjcxx'}).then(res=>{

      this.Statisticslist= res.results.data
      })
    },
    getScintroduct() {
      apiUrls.getStatistics({node: 'gxbscsjcqkjj'}).then(res => {

        this.Scintroduct = res.results.data
      })
    },
    getdonatetoknow() {
      apiUrls.getStatistics({node: 'gxbjxxz'}).then(res => {

        this.donatetoknow = res.results.data
      })
    },
    getdrystyle() {
      apiUrls.getstemcell({node: 'zgzyzfc'}).then(res=>{

      this.drystyle= res.results.data
      })
    },
    getpopular() {
      apiUrls.getstemcell({node: 'zgxb_kpzs'}).then(res=>{

      this.popularlist= res.results.data
      })
    }
  }
}
</script>

<style lang="less" scoped>
.stemcells {
  background-color: #f5f5f5;
  .cells-container {
    width: 1200px;
    min-height: 400px;
    padding-bottom: 40px;
    margin: 0 auto;
    background-color: #f5f5f5;

    &::before {
        display: block;
        content: ".";
        clear: both;
        height: 0;
        overflow: hidden;
        visibility: hidden; 
    }
  }
}
</style>
